import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FileUploadOutlined as ExportIcon } from '@mui/icons-material';
import { OrderData } from './orderDetailDialog';
import * as XLSX from 'xlsx';
import { useSnackbar } from 'notistack';
import { OrderState } from '../graphql_types/globalTypes';
import { useTranslation } from 'react-i18next';
import { padStart } from '../utils';

interface IDownloadExcelButtonProp {
	loading?: boolean;
	data: OrderData[];
}

export const DownloadExcelButton: React.FC<IDownloadExcelButtonProp> = ({ loading = false, data }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDownload = (courierName: 'hanmi' | 'mail' | 'maple' | 'woori' | 'woori_new') => () => {
		if (!data.length) {
			enqueueSnackbar(`Data is not selected`, { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		//console.log(data);
		const exportData: any[] = [];
		const shippingNoSheet: any[] = [];
		let FILE_NAME = ``;
		if (courierName === 'hanmi') {
			data
				.sort((a: any, b: any) => (a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0))
				.forEach((orderInfo, idx) => {
					//Set Basic Info
					let tempData: any = {};
					//Fixed Values
					tempData['번호'] = idx + 1;
					tempData['비즈니스회원 아이디'] = '12398';
					tempData['보내는 사람(영문)이름'] = 'Kevin Lee';
					tempData['보내는이 이메일'] = 'support@vitashop.ca';
					tempData['전화번호'] = '1-647-847-1554';
					tempData['주소'] = '7183 Yonge st. unit 38 Thornhill ON L3T0C7';
					tempData['관리번호'] = '';

					//Values from orderData
					tempData['받는 사람 이름(한글)'] = orderInfo.pccName;
					tempData['휴대폰'] = orderInfo.buyerPhone;
					tempData['전화'] = orderInfo.recieverPhone;
					tempData['우편번호'] = orderInfo.postal;
					tempData['우편번호주소'] = orderInfo.addr;
					tempData['상세주소'] = orderInfo.addrDetail;
					tempData['개인통관고유부호'] = orderInfo.PCC;
					tempData['배송메모'] = orderInfo.msg;

					//Fixed Values
					tempData['받는이 구분(1:개인 2:사업자)'] = '1';
					tempData['거래형태 (A:전자상거래 개인직접수입형 B:전자상거래 구매대행  C:일반 )'] = 'a';
					tempData['가로(inch)'] = '1';
					tempData['세로(inch)'] = '1';
					tempData['높이(inch)'] = '1';
					tempData['중량'] = '1';
					tempData['중량단위(1:Kg  2:Lbs)'] = '2';
					tempData['Box수량'] = '1';
					tempData['HS CODE'] = '19';

					for (let i = 0; i < (orderInfo.orderProducts ? orderInfo.orderProducts.length : 0); i++) {
						const product = orderInfo.orderProducts ? orderInfo.orderProducts[i] : null;
						if (product) {
							let addProduct: any = {};
							addProduct[`상품명`] = product.alias;
							addProduct[`브랜드`] = product.brand;
							addProduct[`단가`] = product.reportableUnitPrice;
							addProduct[`수량`] = (product.qty as number) * (product.productQty as number);

							if (!i) exportData.push({ ...tempData, ...addProduct });
							else exportData.push(addProduct);
						}
					}
					shippingNoSheet.push({ id: orderInfo.id, referenceNo: orderInfo.referenceNo, orderNo: '', shippingNo: '', name: orderInfo.pccName });
				});

			FILE_NAME = `Hanmi-Upload.xlsx`;
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(exportData);
			const shipping = XLSX.utils.json_to_sheet(shippingNoSheet);

			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			XLSX.utils.book_append_sheet(wb, shipping, 'Shipping');
			XLSX.writeFile(wb, FILE_NAME);
		} else if (courierName === 'mail' || courierName === 'woori_new') {
			const d = new Date();
			data
				.sort((a: any, b: any) => (a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0))
				.forEach((orderInfo, idx) => {
					if (orderInfo.orderState === OrderState.Canceled) return;
					//Set Basic Info
					let tempData: any = {};
					//Fixed Values
					tempData['번호'] = idx + 1;
					tempData['MAWB No'] = '';
					tempData['HAWB No'] = '';
					tempData['주문번호'] = orderInfo.orderNo;
					tempData['발송일'] = `${d.getFullYear()}-${padStart(d.getMonth() + 1, 2, '0')}-${padStart(d.getDate(), 2, '0')}`;
					tempData['발송인 이름'] = 'Kevin Lee';
					tempData['주소'] = '7181 Yonge st. unit 38';
					tempData['city'] = 'Thornhill';
					tempData['state'] = 'ON';
					tempData['발송지 우편번호'] = 'L3T 0C7';
					tempData['발송지 전화번호'] = '647-847-1552';
					//Values from orderData
					tempData['수취인이름'] = `${orderInfo.pccName}`.replaceAll(' ', '-').trim();
					tempData['우편번호'] = orderInfo.postal + '';
					tempData['주소1'] = orderInfo.addr;
					tempData['주소2'] = orderInfo.addrDetail || '.';
					tempData['전화번호'] = orderInfo.buyerPhone;
					tempData['휴대폰'] = orderInfo.pccPhone || orderInfo.recieverPhone;
					tempData['메모'] = orderInfo.msg;

					tempData['개인통관고유부호'] = orderInfo.PCC;
					tempData['통관구분'] = 'D';
					tempData['항목구분'] = '2';
					tempData['HS코드'] = '210690';
					tempData['전자상거래유형코드'] = '';
					tempData['해외판매자부호'] = '';
					tempData['해외판매자명'] = '';
					tempData['구매대행업자부호'] = '';
					tempData['구매대행업자명'] = '';
					tempData['판매중개자부호'] = '';
					tempData['판매중개자명'] = '';
					tempData['판매자주문번호'] = '';
					tempData['acctual weight'] = getActualWeight(orderInfo.orderProducts as any[]);
					tempData['c/t'] = '1';

					//for (let i = 0; i < (orderInfo.orderProducts ? orderInfo.orderProducts.length : 0); i++) {
					for (let i = 0; i < 40; i++) {
						if (Array.isArray(orderInfo.orderProducts) && (i < orderInfo.orderProducts.length)) {
							const product = orderInfo.orderProducts[i];
							tempData[`품명${i + 1}`] = product.alias;
							tempData[`url${i + 1}`] = '';
							tempData[`수량${i + 1}`] = (product.qty as number) * (product.productQty as number);
							tempData[`단가${i + 1}`] = product.reportableUnitPrice;
							tempData[`합계${i + 1}`] = (product.reportableUnitPrice as number) * (product.qty as number) * (product.productQty as number);
						}
						else{
							tempData[`품명${i + 1}`] = '';
							tempData[`url${i + 1}`] = '';
							tempData[`수량${i + 1}`] = '';
							tempData[`단가${i + 1}`] = '';
							tempData[`합계${i + 1}`] = '';
						}
					}
					exportData.push(tempData);
					shippingNoSheet.push({ id: orderInfo.id, referenceNo: orderInfo.referenceNo, orderNo: tempData['주문번호'], shippingNo: '', name: orderInfo.pccName });
				});

			FILE_NAME = `${courierName}-Upload.xlsx`;
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(exportData);
			const shipping = XLSX.utils.json_to_sheet(shippingNoSheet);

			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			XLSX.utils.book_append_sheet(wb, shipping, 'Shipping');
			//XLSX.utils.book_append_sheet(wb2, ws2, "Sheet2");
			XLSX.writeFile(wb, FILE_NAME);
		} else if (courierName === 'maple') {
			data
				.sort((a: any, b: any) => (a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0))
				.forEach((orderInfo: any, idx) => {
					if (orderInfo.orderState === OrderState.Canceled) return;
					//Set Basic Info
					let tempData: any = {};
					tempData['번호'] = idx + 1;
					//Fixed Values
					tempData['HAWB'] = '';
					tempData['이름'] = 'VITASHOP'; //보내는사람 이름
					tempData['전화'] = '647-847-1552'; //보내는사람 전화번호
					tempData['주소'] = '7181 Yonge St #38, Markham, ON L3T 0C7'; //보내는 사람 주소
					tempData['관리번호 (통관과는 무관한 업체 관리번호)'] = orderInfo.orderNo;
					tempData['박스코드'] = '';
					tempData['POL (CAYYZ 고정)'] = 'CAYYZ';

					//Values from orderData
					tempData['이름(한글)'] = orderInfo.pccName;
					tempData['연락처1'] = orderInfo.recieverPhone || orderInfo.buyerPhone || '';
					tempData['연락처2'] = orderInfo.buyerPhone || orderInfo.recieverPhone || '';
					tempData['우편번호'] = orderInfo.postal;
					tempData['주소 (전체주소 기재)'] = orderInfo.addr + ' ' + orderInfo.addrDetail;
					tempData['상세주소 ("."점 하나 고정)'] = '.';
					tempData['주민/사업자번호'] = orderInfo.PCC;
					tempData['배송메모'] = `${orderInfo.msg}`.trim();
					tempData['받는이 구분(1:개인,2:사업자)'] = '1';

					tempData['전자상거래(1:전자상거래 구매대행, 2:일반(개인 대 개인), 3:전자상거래 개인직접수입형))'] = '1';
					tempData['홈페이지 주소(개인 대 개인 일 경우 미기재, 미기재되어야 목록통관 가능)'] = orderInfo.website || 'coupang.com';

					for (let i = 0; i < (orderInfo.orderProducts ? orderInfo.orderProducts.length : 0); i++) {
						const product = orderInfo.orderProducts ? orderInfo.orderProducts[i] : null;
						const wt = getActualWeight(orderInfo.orderProducts as any[]);

						tempData['가로(Cm)'] = i === 0 ? (wt >= 1 ? 24 : 15) : 0;
						tempData['세로(Cm)'] = i === 0 ? (wt >= 1 ? 16 : 10) : 0;
						tempData['높이(Cm)'] = i === 0 ? (wt >= 1 ? 16 : 10) : 0;
						tempData['중량'] = i === 0 ? wt : 0;
						tempData['중량단위(1:Kg, 2:Lbs)'] = '1';
						tempData['Box 수량'] = i === 0 ? '1' : '0';
						tempData['주문신청("0" 고정)'] = '0';
						tempData['통관지정번호'] = '';
						tempData['Gmarket체결번호'] = '';
						if (product) {
							tempData[`상품명 (품목 + 브랜드 + 규격 등 정확한 품목 기재)`] = product.alias;
							tempData[`브랜드`] = '';
							tempData[`단가(USD)`] = product.reportableUnitPrice as number;
							tempData[`수량`] = (product.qty as number) * (product.productQty as number);
							tempData[`목록허용품목코드 (HS CODE 6자리)`] = '210690';
						}

						tempData['Freight Term(Prepaid:P,Collect:C)'] = 'c';
						//Empty columns
						tempData['Customer Order NO'] = '';
						tempData['우체국 송장번호'] = '';
						tempData['로컬 언어 상품명'] = '';
						tempData['통화코드(USD, JPY)'] = '';
						tempData['해외 SALES TAX '] = '';
						tempData['해외 내 DELIVERY FEE'] = '';
						tempData['식품종류'] = '';
						tempData['제조사'] = '';
						tempData['제조국가코드'] = '';
						tempData['영업등록번호'] = '';
						tempData['상호'] = '';
						tempData['대표자'] = '';
						tempData['대표자연락처'] = '';
						tempData['대표자주소'] = '';
						tempData['쇼핑몰주소'] = '';
						tempData['로컬배송(Y/N)'] = '';
						tempData['Mall ID'] = '';
						tempData['Mall Item Code'] = '';
						tempData['제품URL주소'] = '';
						tempData['성분'] = '';
						tempData['길이단위(1:cm, 2:inch)'] = '';
						tempData['실화주ID'] = '';
						tempData['CARR CD(운송사)'] = '';
						tempData['신용/착불(0/1)'] = '';
						tempData['반품여부(Y/N)'] = '';
						tempData['전자상거래 유형코드'] = 'A';
						exportData.push({ ...tempData });
					}

					shippingNoSheet.push({ id: orderInfo.id, referenceNo: orderInfo.referenceNo, orderNo: tempData['관리번호 (통관과는 무관한 업체 관리번호)'], shippingNo: '', name: orderInfo.pccName });
				});

			for (let i = 0; i < exportData.length; i++) {
				exportData[i]['번호'] = i + 1;
			}

			FILE_NAME = `Maple-Upload(new).xlsx`;
			const wb = XLSX.utils.book_new();
			const headers = [
				[
					'번호',
					'HAWB',
					'보내는 사람(영문)',
					'보내는 사람(영문)',
					'보내는 사람(영문)',
					'관리번호 (통관과는 무관한 업체 관리번호)',
					'박스코드',
					'POL (CAYYZ 고정)',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'받으시는 분 정보',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'일반/무게',
					'아이템 목록(영문)',
					'아이템 목록(영문)',
					'아이템 목록(영문)',
					'아이템 목록(영문)',
					'아이템 목록(영문)',
					'Freight Term(Prepaid:P,Collect:C)',
					'Customer Order NO',
					'우체국 송장번호',
					'로컬 언어 상품명',
					'통화코드(USD, JPY)',
					'해외 SALES TAX ',
					'해외 내 DELIVERY FEE',
					'제품정보',
					'제품정보',
					'제품정보',
					'구매대행사',
					'구매대행사',
					'구매대행사',
					'구매대행사',
					'구매대행사',
					'구매대행사',
					'로컬배송(Y/N)',
					'Mall ID',
					'Mall Item Code',
					'제품URL주소',
					'성분',
					'길이단위(1:cm, 2:inch)',
					'실화주ID',
					'CARR CD(운송사)',
					'신용/착불(0/1)',
					'반품여부(Y/N)',
					'전자상거래 유형코드',
				],
				[],
			];
			const ws = XLSX.utils.aoa_to_sheet(headers);
			const shipping = XLSX.utils.json_to_sheet(shippingNoSheet);

			XLSX.utils.sheet_add_json(ws, exportData, { origin: 1 });
			ws['!merges'] = [
				{ s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, //A
				{ s: { r: 0, c: 1 }, e: { r: 1, c: 1 } }, //B
				{ s: { r: 0, c: 2 }, e: { r: 0, c: 4 } }, //C-E
				{ s: { r: 0, c: 5 }, e: { r: 1, c: 5 } }, //F
				{ s: { r: 0, c: 6 }, e: { r: 1, c: 6 } }, //G
				{ s: { r: 0, c: 7 }, e: { r: 1, c: 7 } }, //H
				{ s: { r: 0, c: 8 }, e: { r: 0, c: 16 } }, //I-Q
				{ s: { r: 0, c: 17 }, e: { r: 0, c: 27 } }, //R-AB
				{ s: { r: 0, c: 28 }, e: { r: 0, c: 32 } }, //AC-AG
				{ s: { r: 0, c: 33 }, e: { r: 1, c: 33 } }, //AH
				{ s: { r: 0, c: 34 }, e: { r: 1, c: 34 } }, //AI
				{ s: { r: 0, c: 35 }, e: { r: 1, c: 35 } }, //AJ
				{ s: { r: 0, c: 36 }, e: { r: 1, c: 36 } }, //AK
				{ s: { r: 0, c: 37 }, e: { r: 1, c: 37 } }, //AL
				{ s: { r: 0, c: 38 }, e: { r: 1, c: 38 } }, //AM
				{ s: { r: 0, c: 39 }, e: { r: 1, c: 39 } }, //AN
				{ s: { r: 0, c: 40 }, e: { r: 0, c: 42 } }, //AO-AQ
				{ s: { r: 0, c: 43 }, e: { r: 0, c: 48 } }, //AR-AW
				{ s: { r: 0, c: 49 }, e: { r: 1, c: 49 } }, //AX
				{ s: { r: 0, c: 50 }, e: { r: 1, c: 50 } }, //AY
				{ s: { r: 0, c: 51 }, e: { r: 1, c: 51 } }, //AZ
				{ s: { r: 0, c: 52 }, e: { r: 1, c: 52 } }, //BA
				{ s: { r: 0, c: 53 }, e: { r: 1, c: 53 } }, //BB
				{ s: { r: 0, c: 54 }, e: { r: 1, c: 54 } }, //BC
				{ s: { r: 0, c: 55 }, e: { r: 1, c: 55 } }, //BD
				{ s: { r: 0, c: 56 }, e: { r: 1, c: 56 } }, //BE
				{ s: { r: 0, c: 57 }, e: { r: 1, c: 57 } }, //BF
				{ s: { r: 0, c: 58 }, e: { r: 1, c: 58 } }, //BG
				{ s: { r: 0, c: 59 }, e: { r: 1, c: 59 } }, //BH
			];

			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			XLSX.utils.book_append_sheet(wb, shipping, 'Shipping');
			XLSX.writeFile(wb, FILE_NAME);
		} 
		/* Currently not use
		else if (courierName === 'woori') {
			data
				.sort((a: any, b: any) => (a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0))
				.forEach((orderInfo: any, idx) => {
					if (orderInfo.orderState === OrderState.Canceled) return;
					//Set Basic Info
					let tempData: any = {};
					//Fixed Values
					tempData['Hawb no'] = '';
					//yymmdd000CODE
					//if (orderInfo.customer) tempData["Order NO."] = `${yymmdd()}${padStart(idx + 1, 3, "0")}${orderInfo.customer.delimiter}`;
					//else tempData["Order NO."] = `${yymmdd()}${padStart(idx + 1, 3, "0")}`;
					tempData['Order NO.'] = orderInfo.orderNo;
					tempData['NAME'] = 'Kevin Lee';
					tempData['TEL'] = '647-797-2348';
					tempData['ADD.'] = '7181 Yonge St #38, Markham, ON L3T 0C7';

					//Values from orderData
					tempData['Name Korean'] = orderInfo.pccName;
					tempData['Phone'] = orderInfo.recieverPhone || orderInfo.buyerPhone;
					tempData['CellPhone'] = orderInfo.pccPhone || orderInfo.buyerPhone || orderInfo.recieverPhone;
					tempData['ZipCode'] = orderInfo.postal;
					tempData['Address Line1'] = orderInfo.addr + ' ' + orderInfo.addrDetail;
					tempData['Address Line2'] = '';
					tempData['PCC'] = orderInfo.PCC;
					tempData['Delivery Note'] = `${orderInfo.msg}`.trim();

					//Fixed Values
					tempData['Classification'] = '1';
					tempData['Need Clearance'] = '1';
					tempData['Ecommerce Type'] = 'A';
					tempData['Site Address'] = orderInfo.website;
					tempData['Width'] = '';
					tempData['Length'] = '';
					tempData['Height'] = '';
					tempData['Weight'] = getActualWeight(orderInfo.orderProducts as any[]);
					tempData['BoxQty'] = 1;

					tempData['전자상거래 유형'] = ''; // A: 해외판매자 직접 구매, B:구매대행, C:배송대행, Z:확인불가
					tempData['해외판매자 부호'] = '';
					tempData['해외판매자 상호'] = '';
					tempData['구매/배송대행업체 부호'] = '';
					tempData['구매/배송대행업체 상호'] = '';
					tempData['판매대행업체 부호'] = '';
					tempData['판매대행업체 상호'] = '';

					for (let i = 0; i < (orderInfo.orderProducts ? orderInfo.orderProducts.length : 0); i++) {
						const product = orderInfo.orderProducts ? orderInfo.orderProducts[i] : null;
						let addProduct: any = {};
						if (product) {
							addProduct[`ITEM DESCRIPTION`] = product.alias || product.productName;
							addProduct[`Brand`] = product.brand;
							addProduct[`Unit Price`] = product.reportableUnitPrice || product.unitPrice;
							addProduct[`Q'TY`] = (product.qty as number) * (product.productQty as number);
							addProduct[`Product Code`] = '';
							addProduct[`HS Code`] = '210690';

							addProduct[`통관시 요청사항`] = '';
							addProduct['배송요청사항'] = '';
						}
						if (!i) exportData.push({ ...tempData, ...addProduct });
						else exportData.push(addProduct);
					}
					shippingNoSheet.push({ id: orderInfo.id, referenceNo: orderInfo.referenceNo, orderNo: tempData['Order NO.'], shippingNo: '', name: orderInfo.pccName });
				});

			FILE_NAME = `Woori-Upload.xlsx`;
			const wb = XLSX.utils.book_new();
			const headers = [
				[
					'Hawb no',
					'오더번호',
					'보내는 사람(영문)_SHIPPER (ENGLISH)',
					'보내는 사람(영문)_SHIPPER (ENGLISH)',
					'보내는 사람(영문)_SHIPPER (ENGLISH)',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'받는 사람(한글)_CONSIGNEE',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'일반/무게_(GENERAL/WEIGHT)',
					'유형별 추가 정보',
					'유형별 추가 정보',
					'유형별 추가 정보',
					'유형별 추가 정보',
					'유형별 추가 정보',
					'유형별 추가 정보',
					'유형별 추가 정보',
					'아이템 목록(영문)_ITEM LIST (ENGLISH)',
					'아이템 목록(영문)_ITEM LIST (ENGLISH)',
					'아이템 목록(영문)_ITEM LIST (ENGLISH)',
					'아이템 목록(영문)_ITEM LIST (ENGLISH)',
					'아이템 목록(영문)_ITEM LIST (ENGLISH)',
					'아이템 목록(영문)_ITEM LIST (ENGLISH)',
					'요청사항',
					'요청사항',
				],
				[],
			];
			const ws = XLSX.utils.aoa_to_sheet(headers);
			const shipping = XLSX.utils.json_to_sheet(shippingNoSheet);
			ws['!merges'] = [
				{ s: { r: 0, c: 2 }, e: { r: 0, c: 4 } },
				{ s: { r: 0, c: 5 }, e: { r: 0, c: 13 } },
				{ s: { r: 0, c: 14 }, e: { r: 0, c: 21 } },
				{ s: { r: 0, c: 22 }, e: { r: 0, c: 28 } },
				{ s: { r: 0, c: 29 }, e: { r: 0, c: 34 } },
				{ s: { r: 0, c: 35 }, e: { r: 0, c: 36 } },
			];
			XLSX.utils.sheet_add_json(ws, exportData, { origin: 1 });

			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			XLSX.utils.book_append_sheet(wb, shipping, 'Shipping');
			XLSX.writeFile(wb, FILE_NAME);
		}
		else if (courierName === 'woori_new') {
			data
				.sort((a: any, b: any) => (a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0))
				.forEach((orderInfo: any, idx) => {
					if (orderInfo.orderState === OrderState.Canceled) return;
					//Set Basic Info
					let tempData: any = {};
					//Fixed Values
					tempData['Hawb no(운송장번호)'] = '';
					//yymmdd000CODE
					//if (orderInfo.customer) tempData["Order NO."] = `${yymmdd()}${padStart(idx + 1, 3, "0")}${orderInfo.customer.delimiter}`;
					//else tempData["Order NO."] = `${yymmdd()}${padStart(idx + 1, 3, "0")}`;
					tempData['관리번호(오더번호)'] = orderInfo.orderNo;
					tempData['보내는사람 이름'] = 'Kevin Lee';
					tempData['보내는사람 전화'] = '647-797-2348';
					tempData['보내는사람 주소'] = '7181 Yonge St #38, Markham, ON L3T 0C7';

					//Values from orderData
					tempData['이름(한글)'] = orderInfo.pccName;
					tempData['전화'] = orderInfo.recieverPhone || orderInfo.buyerPhone;
					tempData['휴대폰'] = orderInfo.pccPhone || orderInfo.buyerPhone || orderInfo.recieverPhone;
					tempData['우편번호'] = orderInfo.postal;
					tempData['주소'] = orderInfo.addr + ' ' + orderInfo.addrDetail;
					tempData['상세주소'] = '';
					tempData['개인고유부호/사업자번호'] = orderInfo.PCC;
					tempData['배송메모'] = `${orderInfo.msg}`.trim();

					//Fixed Values
					tempData['받는이 구분(1:개인,2:사업자)'] = '1';
					tempData['통관구분'] = 'A';
					tempData['국내판매 홈페이지 URL'] = orderInfo.website;
					tempData['가로(Cm)'] = '';
					tempData['세로(Cm)'] = '';
					tempData['높이(Cm)'] = '';
					tempData['중량'] = getActualWeight(orderInfo.orderProducts as any[]);
					tempData['중량단위(lbs/kg)'] = '2';
					tempData['Box수량'] = 1;
					tempData['일반신청(0:목록,1:일반)'] = '1';
					tempData['전자상거래 유형'] = ''; // A: 해외판매자 직접 구매, B:구매대행, C:배송대행, Z:확인불가
					tempData['해외판매자 부호'] = '';
					tempData['해외판매자 상호'] = '';
					tempData['구매/배송대행업체 부호'] = '';
					tempData['구매/배송대행업체 상호'] = '';
					tempData['판매대행업체 부호'] = '';
					tempData['판매대행업체 상호'] = '';

					for (let i = 0; i < (orderInfo.orderProducts ? orderInfo.orderProducts.length : 0); i++) {
						const product = orderInfo.orderProducts ? orderInfo.orderProducts[i] : null;
						let addProduct: any = {};
						if (product) {
							addProduct[`상품명`] = product.alias || product.productName;
							addProduct[`브랜드`] = product.brand;
							addProduct[`단가`] = product.reportableUnitPrice || product.unitPrice;
							addProduct[`수량`] = (product.qty as number) * (product.productQty as number);
							addProduct[`상품코드`] = '';
							addProduct[`HS`] = '210690';
							addProduct[`주문번호(합배송기준)`] = orderInfo.orderNo;
						}
						if (!i) exportData.push({ ...tempData, ...addProduct });
						else exportData.push(addProduct);
					}
					shippingNoSheet.push({ id: orderInfo.id, referenceNo: orderInfo.referenceNo, orderNo: tempData['관리번호(오더번호)'], shippingNo: '', name: orderInfo.pccName });
				});

			FILE_NAME = `Woori-Upload_new.xls`;
			const wb = XLSX.utils.book_new();
			const headers = [
				[
					'form:v23'
				]
			];
			const ws = XLSX.utils.aoa_to_sheet(headers);
			const shipping = XLSX.utils.json_to_sheet(shippingNoSheet);
			XLSX.utils.sheet_add_json(ws, exportData, { origin: 1 });

			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			XLSX.utils.book_append_sheet(wb, shipping, 'Shipping');
			XLSX.writeFile(wb, FILE_NAME);
		}
		*/
		enqueueSnackbar(`Download ${FILE_NAME}`, { variant: 'success', autoHideDuration: 3000 });
		handleClose();
	};

	const getActualWeight = (orderProducts: any[]) => {
		let weight = 0;
		for (let i = 0; i < orderProducts.length; i++) {
			//console.log(orderProducts[i]);
			weight += orderProducts[i].productWeight * +orderProducts[i].qty;
		}
		//console.log(weight);
		return weight + 0.15;
	};

	function getFixedWeight(qty: number) {
		let tempGwt = 'N/A';
		if (qty < 7) {
			const weightList = ['0.2', '0.3', '0.5', '0.7', '0.8', '1.0'];
			tempGwt = weightList[qty - 1];
		}

		return tempGwt;
	}

	function getTotalQty(orderProducts: any[]) {
		let totalQty = 0;
		for (let i = 0; i < orderProducts.length; i++) {
			totalQty += +orderProducts[i].qty * +orderProducts[i].productQty;
		}
		return totalQty;
	}

	return (
		<>
			<LoadingButton loading={loading} size='small' loadingPosition='start' startIcon={<ExportIcon fontSize='small' />} variant='outlined' onClick={handleMenu}>
				{t('Export')}
			</LoadingButton>
			<Menu
				sx={{ mt: '35px' }}
				id='menu-download'
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				{/* 05.03.2022 Deprecated
				<MenuItem onClick={handleDownload("hanmi")} sx={{ margin: 1, "&:hover": { backgroundColor: "primary.main", color: "primary.contrastText", borderRadius: "5px" } }}>
					Hanmi
				</MenuItem>*/}
				<MenuItem onClick={handleDownload("mail")} sx={{ margin: 1, "&:hover": { backgroundColor: "primary.main", color: "primary.contrastText", borderRadius: "5px" } }}>
					Mail
				</MenuItem> 
				<MenuItem onClick={handleDownload('maple')} sx={{ margin: 1, '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText', borderRadius: '5px' } }}>
					Maple
				</MenuItem>
				{/* <MenuItem onClick={handleDownload('woori')} sx={{ margin: 1, '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText', borderRadius: '5px' } }}>
					Woori
				</MenuItem> */}
				<MenuItem onClick={handleDownload('woori_new')} sx={{ margin: 1, '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText', borderRadius: '5px' } }}>
					Woori
				</MenuItem>
			</Menu>
		</>
	);
};
